<template>
  <div class="attachments">
    <Header back="返回" title="项目列表" index="首页" titleOne="项目管理" titleTwo="项目列表" beforeTitle="编辑" />
    <div class="content">
      <h1>基本信息</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        label-width="80px"
        :label-position="labelPosition"
      >
        <el-form-item label="项目名称">
          <el-input v-model="form.order_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="企业名称">
          <el-input v-model="form.company_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="合同名称">
          <el-input v-model="form.contract_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="项目类型">
          <el-select v-model="form.product_type" placeholder="请选择" disabled>
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年度">
          <el-input v-model="form.order_year"></el-input>
        </el-form-item>
        <el-form-item label="批次">
          <el-input v-model="form.order_batch"></el-input>
        </el-form-item>
        <el-form-item label="截止日期">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.end_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>

        <h1>协助人员</h1>
        <el-table :data="form.helper" style="width: 100%" :key="Math.random()">
          <el-table-column width="50">
            <template slot="header" slot-scope="scope">
              <button type="button" @click="addLine" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                type="button"
                v-if="form.helper.length > 0"
                @click="handleDelete(scope.$index, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <el-table-column label="部门">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.role_id"
                placeholder="请选择"
                @change="(val) => getDepartname(val, scope.$index)"
              >
                <el-option v-for="item in role" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="协助人">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.helper_id"
                placeholder="请选择"
                @change="$forceUpdate()"
                ref="select"
              >
                <el-option
                  v-for="item in helper_id[scope.$index]"
                  :key="item.id"
                  :label="item.true_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      isDisable: false,
      labelPosition: "top",
      form: {
        order_name: "",
        company_name: "",
        contract_name: "",
        product_type: "",
        order_year: "",
        order_batch: "",
        end_time: "",
        helper: [],
      },
      role_id: "",
      formRule: {},
      options: [],
      role: [],
      helper_id: new Array(),
      help_name: "",
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    this.getproductType();
    this.getRoleId();
    if (this.meber_id) {
      this.axios
        .get("/api/order/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          res.data.helper.forEach((item, index) => {
            item.role_id = parseInt(item.role_id);
            this.getDepartname(item.role_id, index);
          });
          this.form = res.data;
        });
    }
  },
  methods: {
    getproductType () {
      this.axios.get("/api/policy/select_product_type").then((res) => {
        this.options = res.data;
      });
    },
    getRoleId () {
      this.axios.get("/api/organization/select_organization").then((res) => {
        this.role = res.data;
      });
    },
    getDepartname (id, param) {
      this.axios
        .get("/api/member/select_member", {
          params: {
            organization_id: id,
          },
        })
        .then((res) => {
          this.helper_id[param] = res.data;
          console.log(this.helper_id[param]);
          this.$forceUpdate();
        });
    },
    getHelpname (id, index) {
      this.helper_id[index].find((item) => {
        if (item.id == id) {
          this.form.helper[index].help_name = item.true_name;
        }
      });
    },
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/order/store_info", this.form)
            .then((res) => {
              this.$router.push("/project/index");
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.isDisable = true;
              this.btnLoading = false
            })
            .catch((res) => {
              this.btnLoading = false
              console.log("err:", res);
            });
        } else {
          this.$message.error("");
        }
      });
    },
    addLine () {
      //添加行数
      var newValue = {};
      this.form.helper.push(newValue);
    },
    handleDelete (index) {
      //删除行数
      this.form.helper.splice(index, 1);
    },
    save () {
      //这部分应该是保存提交你添加的内容
      console.log(JSON.stringify(this.form.helper));
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}
</style>
